import React from 'react';
import './checkbox.component.scss';

export const Checkbox = ({ name, checked, onChange, ...rest }) => {
    return (
        <div className="checkbox-container">
            <input type="checkbox" checked={checked} name={name} onChange={onChange} { ...rest } />
            <div className={`styled-checkbox${checked ? ' checked': ''}`}>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.37487 0L3.84251 4.65831L1.62513 2.37952L0 4.05037L3.84131 8L10 1.67003L8.37487 0Z" fill="white"/>
                </svg>
            </div>
        </div>
    )
}
