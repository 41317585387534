import React, { useState, useEffect, useCallback } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { FormContext } from '../utils/form.context';

import { Input } from './input.component';
import { FormControl } from './form-control.component';
import { LoadingSpinner } from './loading.spinner.component';
import { useUser } from '../hooks/use-user.hook';
import { useDispatch } from 'react-redux';
import { updateEmail, updatePassword } from '../services/users.service';
import { Link } from 'gatsby';
import { Checkbox2 } from '../components/checkbox2.component';
import { updateEmailPreference } from '../services/users.service';
import { setUser } from '../states/authentication.slice';
import './edit-user-info.component.scss';

const deepCopy = obj => JSON.parse(JSON.stringify(obj))

export const EditUserInfo = ({ isOrganization = false }) => {

    const { t } = useI18next();
    const { user } = useUser();
    const [ emailUpdateErrors, setEmailUpdateErrors ] = useState();
    const [ passwordUpdateErrors, setPasswordUpdateErrors ] = useState();
    const [ isUpdatingEmail, setIsUpdatingEmail ] = useState(false);
    const [ isUpdatingPassword, setIsUpdatingPassword ] = useState(false);
    const [ isUpdatedEmail, setIsUpdatedEmail ] = useState(false);
    const [ isUpdatedEmailPreference, setIsUpdatedEmailPreference ] = useState(false);
    const [ isUpdatedPassword, setIsUpdatedPassword ] = useState(false);

    const [ updateUser, setUpdateUser ] = useState({});

    const dispatch = useDispatch();
    const [ ccResult, setCcResult ] = useState(true);

    useEffect(() => {
        if (user && user.organization) {
            setCcResult(user.organization.ccResult);
        }
    }, [ user ]);

    useEffect(() => {
        setUpdateUser(user);
    }, [ user ]);

    const handleOnChange = (e) => {
        setUpdateUser({...updateUser, [e.target.name]: e.target.value});
    };

    const handleUpdateEmail = () => {
        setEmailUpdateErrors();
        setIsUpdatingEmail(true);
        
        const emailToUpdate = {
            userId: updateUser.id,
            newEmailAddress: updateUser.emailAddress,
            password: updateUser.password
        }

        updateEmail(emailToUpdate)
            .then(() => setIsUpdatedEmail(true))
            .catch(error => setEmailUpdateErrors(error.response.data.message))
            .finally(() => setIsUpdatingEmail(false));
    };

    const handleConfirmChange = useCallback(() => {
        updateEmailPreference(ccResult)
        .then(response => {
            const updateUser = deepCopy(user);
            updateUser.organization.ccResult = ccResult;
            dispatch(setUser(updateUser));
            setIsUpdatedEmailPreference(true);
        })
        .catch(error => console.error('update email preference', error));
    }, [ ccResult ]);

    const handleUpdatePassword = () => {
        setPasswordUpdateErrors();
        if (updateUser.newPassword !== updateUser.repeatPassword) {
            setPasswordUpdateErrors([{property: "repeatPassword", errors: ["password does not match"]}]);
            return;
        }

        const passwordToUpdate = {
            userId: updateUser.id,
            currentPassword: updateUser.currentPassword,
            newPassword: updateUser.newPassword,
            repeatPassword: updateUser.repeatPassword,
        }
        
        updatePassword(passwordToUpdate)
            .then(() => setIsUpdatedPassword(true))
            .catch(error => setPasswordUpdateErrors(error.response.data.message))
            .finally(() => setIsUpdatingPassword(false));
    };

    if (isUpdatedEmail) {
        return (
            <>
                <p className="t-sans t-grey9">{t('Please check your new Email address to confirm your change.')}</p>
                <Link to={isOrganization ? '/organization/account-info' : '/dashboard/account-info'} className="hap-button size-small">{t('Dashboard')}</Link>
            </>
        );
    }
    
    if (isUpdatedPassword) {
        return (
            <>
                <p className="t-sans t-grey9">{t('Your password has been updated.')}</p>
                <Link to={isOrganization ? '/organization/account-info' : '/dashboard/account-info'} className="hap-button size-small">{t('Dashboard')}</Link>
            </>
        );
    }

    if (isUpdatedEmailPreference) {
        return (
            <>
                <p className="t-sans t-grey9">{t('Your Email preference has been updated.')}</p>
                <Link to={'/organization'} className="hap-button size-small">{t('Back')}</Link>
            </>
        );
    }

    return (
        <>  
            {user.type === 'ORGANIZATION' && (
                <div className="email-preference">
                    <label className="t-sans email-preference__label">{t('Email preference')}</label>
                    <Checkbox2 checked={ccResult} label={t('Receive result emails')} onChange={() => setCcResult(!ccResult)} />
                    <div className="section--edit-info__button">
                        <button type="button" className="hap-button" onClick={handleConfirmChange}>{t('Confirm change')}</button>
                    </div>
                </div>
            )}

            <FormContext.Provider value={emailUpdateErrors}>
                <form className="flex-form">
                    <FormControl label={t('Current password')} className="mt-remove" name="password">
                        <Input name="password" type="password" placeholder={t('Current password')}  value={updateUser.password||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('Email address')} name="emailAddress">
                        <Input name="emailAddress" placeholder={t('Email address')}  value={updateUser.emailAddress||''} onChange={handleOnChange} />
                    </FormControl>
                    {isUpdatingEmail ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="section--edit-info__button">
                            <button type="button" className="hap-button" onClick={handleUpdateEmail}>{t('Update email')}</button>
                        </div>
                    )}
                </form>
            </FormContext.Provider>
            <FormContext.Provider value={passwordUpdateErrors}>
                <form className="flex-form">
                    <FormControl label={t('Current password')} className="mt-remove" name="currentPassword">
                        <Input name="currentPassword" type="password" placeholder={t('Current password')}  value={updateUser.currentPassword||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('New password')} name="newPassword">
                        <Input name="newPassword" type="password" placeholder={t('New password')}  value={updateUser.newPassword||''} onChange={handleOnChange} />
                    </FormControl>
                    <FormControl label={t('Repeat password')} name="repeatPassword">
                        <Input name="repeatPassword" type="password" placeholder={t('Repeat password')}  value={updateUser.repeatPassword||''} onChange={handleOnChange} />
                    </FormControl>
                    {isUpdatingPassword ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="section--edit-info__button">
                            <button type="button" className="hap-button" onClick={handleUpdatePassword}>{t('Change password')}</button>
                        </div>
                    )}
                </form>
            </FormContext.Provider>
        </>
    );
}