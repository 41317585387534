import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar } from './navbar.component';
import LogoIcon from '../assets/logo-icon.svg';
import { useUser } from '../hooks/use-user.hook';
import Buger from '../components/burger.component';
import { SecureLayout } from './secure-layout.component';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { DashboardBurger } from '../components/dashboard-burger.component';
import { setIsCorporation } from '../states/navbar.slice';

const menuItems = t => [
    {
        title: t('FAQ'),
        to: '/faqs',
        isButton: false
    },
    {
        title: t('Features'),
        to: '/features',
        isButton: false
    },
    {
        title: t('Contact Us'),
        to: '/#contact-us',
        isButton: false
    },
    {
        title: t('Login'),
        to: '/account/login',
        isButton: false
    },
    {
        title: t('Corporations'),
        to: '/corporations',
        isButton: false
    },
    {
        title: t('Test Kit'),
        to: '/register',
        isButton: true
    }
];

const menuItemsSecure = t => [
    {
        title: t('FAQ'),
        to: '/faqs',
        isButton: false
    },
    {
        title: t('Dashboard'),
        to: '/dashboard',
        isButton: false
    },
    {
        title: t('Logout'),
        to: '/logout',
        isButton: false
    },
    {
        title: t('Test Kit'),
        to: '/register',
        isButton: true
    }
];

const corpsMenuItems = t => [
    {
        title: t('FAQ'),
        to: '/corporations/faqs',
        isButton: false
    },
    {
        title: t('Features'),
        to: '/corporations/features',
        isButton: false
    },
    {
        title: t('Contact Us'),
        to: '/corporations#contact-us',
        isButton: false
    },
    {
        title: t('Login'),
        to: '/account/login',
        isButton: false
    },
    {
        title: t('Individuals'),
        to: '/',
        isButton: false
    },
    {
        title: t('Test Kit'),
        to: '/register',
        isButton: true
    }
]

export const Logo = ({linkTo='/'}) => <Link to={linkTo}><LogoIcon /></Link>

export const MenuLayout = ({ children, className, isAbs=false, corporations, ...rest }) => {
    const { t } = useI18next();
    const { loggedIn } = useUser();
    const dispatch = useDispatch();
    const { isCorporation } = useSelector(s => s.navbar);

    useEffect(() => {
        const currentPath = window.location.pathname;

        if (currentPath) {
            if (currentPath.includes('/corporations')) {
                dispatch(setIsCorporation(true));
            } else {
                dispatch(setIsCorporation(false));
            }
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname !== '/account/register') {
            window.sessionStorage.setItem('current_page', window.location.pathname);
        }
    }, []);

    return <SecureLayout {...rest} className={['register-layout', className].join(' ')}>
        <Navbar
            className={`navbar-mobile${isAbs ? ' p-abs' : ''}`}
            left={<Buger />}
            center={<Logo linkTo={isCorporation ? '/corporations' : '/'} />}
            right={loggedIn && <DashboardBurger />}
            menuItems={isCorporation ? corpsMenuItems(t) : (loggedIn ? menuItemsSecure(t) : menuItems(t))}
        />

        {loggedIn ? (
            <Navbar
                className={`navbar-desktop${isAbs ? ' p-abs' : ''}`}
                left={<Logo linkTo={isCorporation ? '/corporations' : '/'} />}
                isLoggedInDesktop={true}
            />
        ) : (
            <Navbar
                className={`navbar-desktop${isAbs ? ' p-abs' : ''}`}
                left={<Logo linkTo={isCorporation ? '/corporations' : '/'} />}
                menuItems={isCorporation ? corpsMenuItems(t) : menuItems(t)}
            />
        )}
        {children}
    </SecureLayout>;
};