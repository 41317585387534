import React from 'react';
import './burger.component.scss';
import disableScroll from 'disable-scroll';
import { setIsOpen, setIsOpenDashSidebar } from '../states/sidebar.slice';
import { useDispatch, useSelector } from 'react-redux';

export default function Burger() {
    const dispatch = useDispatch();
    const { isOpen, isOpenDashSidebar } = useSelector(s => s.sidebar);

    const handleBurgerToggle = () => {
        
        if (isOpenDashSidebar) {
            dispatch(setIsOpenDashSidebar(false));
            return;
        }

        if (!isOpen) disableScroll.on();
        else disableScroll.off();

        dispatch(setIsOpen(!isOpen));
    }

    return (
        <React.Fragment>
            <div className={`burger${(isOpen || isOpenDashSidebar) ? ' active' : ''}`} aria-label="Toggle menu" aria-expanded={(isOpen || isOpenDashSidebar)} onClick={handleBurgerToggle}>
                <span />
                <span />
                <span />
            </div>
            <p className={`t-burger t-sans${(isOpen || isOpenDashSidebar) ? ' active' : ''}`}>{!(isOpen || isOpenDashSidebar) ? 'MENU' : 'CLOSE'}</p>
        </React.Fragment>
    )
}
