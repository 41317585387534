import React from 'react';
import { useDispatch } from 'react-redux';
import './dashboard-burger.component.scss';

import { useDefaultUser } from '../hooks/use-default-user.hook';
import { setIsOpenDashSidebar } from '../states/sidebar.slice';

export const DashboardBurger = () => {
    const dispatch = useDispatch();
    const { defaultUser } = useDefaultUser();
    const handleOnUserClick = () => dispatch(setIsOpenDashSidebar(true));

    return (
        <button className="dashboard-burger-button" type="button" onClick={handleOnUserClick}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.7322 4.25753C15.9416 2.47691 13.5208 1.46655 10.9999 1.46655C8.469 1.46655 6.04815 2.4669 4.25753 4.25753C2.4669 6.04815 1.46655 8.469 1.46655 10.9999C1.46655 13.5308 2.4669 15.9516 4.25753 17.7422C6.04815 19.5329 8.469 20.5332 10.9999 20.5332C13.5308 20.5332 15.9516 19.5329 17.7422 17.7422C19.5329 15.9516 20.5332 13.5308 20.5332 10.9999C20.5232 8.469 19.5229 6.04815 17.7322 4.25753ZM10.9999 12.5204C8.12888 12.5204 5.62801 13.5308 4.21751 15.0313C3.50727 13.8509 3.09712 12.4704 3.09712 10.9999C3.09712 6.63836 6.63836 3.09712 10.9999 3.09712C15.3614 3.09712 18.9026 6.63836 18.9026 10.9999C18.9026 12.4804 18.4925 13.8509 17.7823 15.0413C16.3718 13.5308 13.8709 12.5204 10.9999 12.5204Z" fill="#2C3B4A"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.2697 4.98769C8.58906 5.38783 7.5387 7.06842 7.93884 8.75901C8.33898 10.4396 10.0196 11.49 11.7102 11.0898C13.3907 10.6897 14.4411 9.0091 14.041 7.3185C13.6508 5.63792 11.9602 4.59755 10.2697 4.98769Z" fill="#2C3B4A"/>
            </svg>
            <span className="t-grey9 t-sans dashboard-burger-button__username">{defaultUser?.lastName || ''}</span>
        </button>
    );
};