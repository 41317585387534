import React from 'react';
import './loading.spinner.component.scss';

export const LoadingSpinner = ({loading=false}) => (
    <div className={`loading-spinner${loading ? '' : ' fadeout'}`}>
        <div className="spinner">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.9775 0.175089L22.8285 4.03365H22.8282C23.0601 4.28461 23.0601 4.66178 22.8108 4.877L15.4113 12.3249C15.1794 12.5584 14.8054 12.5584 14.5734 12.3249L7.17395 4.877C6.94202 4.64355 6.94202 4.2671 7.17395 4.03365L11.0249 0.175089C11.2568 -0.0583629 11.6308 -0.0583629 11.8628 0.175089L14.4663 2.81309C14.7693 3.11804 15.2331 3.11804 15.5361 2.81309L18.1396 0.175089C18.3716 -0.0583629 18.7456 -0.0583629 18.9775 0.175089ZM0.175089 11.0225L4.03365 7.17155V7.17183C4.28461 6.9399 4.66178 6.9399 4.877 7.18924L12.3249 14.5887C12.5584 14.8206 12.5584 15.1946 12.3249 15.4266L4.877 22.826C4.64355 23.058 4.2671 23.058 4.03365 22.826L0.175089 18.9751C-0.0583626 18.7432 -0.0583626 18.3692 0.175089 18.1372L2.81309 15.5337C3.11804 15.2307 3.11804 14.7669 2.81309 14.4639L0.175089 11.8604C-0.0583629 11.6284 -0.0583629 11.2544 0.175089 11.0225ZM7.17155 25.9664L11.0225 29.8249C11.2544 30.0584 11.6284 30.0584 11.8604 29.8249L14.4639 27.1869C14.7669 26.882 15.2307 26.882 15.5337 27.1869L18.1372 29.8249C18.3692 30.0584 18.7432 30.0584 18.9751 29.8249L22.826 25.9664C23.058 25.7329 23.058 25.3565 22.826 25.123L15.4266 17.6751C15.1946 17.4416 14.8206 17.4416 14.5887 17.6751L7.18924 25.123C6.9399 25.3382 6.9399 25.7154 7.17183 25.9664H7.17155ZM29.8249 18.9775L25.9664 22.8284V22.8282C25.7154 23.0601 25.3382 23.0601 25.123 22.8108L17.6751 15.4113C17.4416 15.1794 17.4416 14.8054 17.6751 14.5734L25.123 7.17395C25.3565 6.94202 25.7329 6.94202 25.9664 7.17395L29.8249 11.0249C30.0584 11.2568 30.0584 11.6308 29.8249 11.8628L27.1869 14.4663C26.882 14.7693 26.882 15.2331 27.1869 15.5361L29.8249 18.1396C30.0584 18.3716 30.0584 18.7456 29.8249 18.9775Z" fill="#66AC46"/>
            </svg>
        </div>
    </div>
);
