import React from 'react';
import { Checkbox } from './checkbox.component';
import './checkbox2.component.scss';

export function Checkbox2({ label, checked, labelSize='default', align='default', onChange }) {
    //export function Checkbox2({ label, isChecked, labelSize='default', align='default', setIsChecked }) {

    return (
        <label className={`check-group ${align}`}>
            <Checkbox checked={checked} onChange={onChange} />
            <span className={`check-label ${labelSize}`}>{label}</span>
        </label>
    )
}
